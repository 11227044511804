export default defineNuxtRouteMiddleware((to) => {
  if (process.server) return

  const { currentCompany } = useCompany()

  if (!currentCompany.value && !to.params.companyId) {
    navigateTo({
      name: 'index',
      query: {
        redirect: to.fullPath
      }
    })
  }
})
